export const state = {
  session: {
    is_admin: false,
  }
};

export const mutations = {
  SET_SESSION_DATA(state, data) {
    state.session = {
      ...data,
      is_admin: data.is_admin || false
    };

    if (data) {
      localStorage.setItem('session', JSON.stringify(state.session));
      sessionStorage.setItem('session', JSON.stringify(state.session));
    }
  },

  CLEAR_SESSION_DATA(state) {
    state.session = { is_admin: false };

    sessionStorage.removeItem('session');
    localStorage.removeItem('session');
  },
};


export const actions = {
  setSessionData({ commit }, session_data) {
    commit('SET_SESSION_DATA', session_data)
  },
  logoutUser({ commit }) {
    commit('CLEAR_SESSION_DATA')
  },
}

export const getters = {
  sessionData: (state) => () => state.session,
  isAdmin: (state) => state.session.is_admin,
};